import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {loadStripe} from '@stripe/stripe-js';

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

// loads the Icon plugin
UIkit.use(Icons);

function centerSliders() {
    // do some slider stuff
    let sliders = document.getElementsByClassName('plans-slider');
    Array.from(sliders).forEach((_slider) => {
        UIkit.slider(_slider).show(1);
    });
}
centerSliders();
window.addEventListener('resize', function (e) {
    if (window.innerWidth >= 960) {
        centerSliders();
    }
});


// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');

// let h1s = document.querySelectorAll('#video-overlay h1');
//
// if (h1s.length) {
//     h1s.forEach(function (h1, index) {
//         h1.style.display = 'none';
//         h1.addEventListener('click', (e) => {
//             let nextIndex = (index+1 >= h1s.length) ? 0 : index+1;
//             h1s[index].style.display = 'none';
//             h1s[nextIndex].style.display = 'block';
//         })
//     });
//     h1s[0].style.display = 'block';
// }

let map;

async function initMap() {
    console.log('initmap');
    if (typeof google == 'undefined') {
        console.log("Couldn't find google");
        return;
    }

    const element = document.getElementById("map");

    const position = { lat: -32.053680, lng: 115.745870 };

    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    map = new Map(element, {
        center: position,
        zoom: 15,
        mapId: "18764c2b940f034d",
        disableDefaultUI: true,
    });

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: 'fSpace Coworking'
    });
}

(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
    key: "AIzaSyB1j5nG2m7SePFsoLUgUOwWX6hm55m2oz0",
    v: "weekly",
    // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
    // Add other bootstrap parameters as needed, using camel case.
});

initMap();


/* ------------- */

async function setup_stripe() {
    const stripe = await loadStripe('pk_test_hBg7VUKMGsCU9oSuqhXteEAs');
    return;

    // The items the customer wants to buy
    const items = [{ id: "xl-tshirt" }];

    let elements;

    // Load Stripe if not on Join page
    if ( ! document.querySelector('body').classList.contains('join') ) {
        return;
    }

    initialize();
    checkStatus();

    document
        .querySelector("#join-form")
        .addEventListener("submit", handleSubmit);

    // Fetches a payment intent and captures the client secret
    async function initialize() {
        try {
            const response = await fetch("http://localhost:57975/stripe/setup", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ items }),
            });
            const { clientSecret } = await response.json();

            elements = stripe.elements({
                clientSecret,
                appearance: { theme: 'stripe' }
            });

            const paymentElementOptions = {
                layout: "tabs",
            };

            const paymentElement = elements.create("payment", paymentElementOptions);
            let val = paymentElement.mount("#payment-element");
        }
        catch (error) {
            UIkit.modal(document.getElementById('error-modal')).show();
            console.log('ERROR', error);
        }

    }

    async function handleSubmit(e) {
        e.preventDefault();

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "http://localhost:4242/checkout.html",
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            showMessage(error.message);
        } else {
            showMessage("An unexpected error occurred.");
        }
    }

// Fetches the payment intent status after payment submission
    async function checkStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
            case "succeeded":
                showMessage("Payment succeeded!");
                break;
            case "processing":
                showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                showMessage("Your payment was not successful, please try again.");
                break;
            default:
                showMessage("Something went wrong.");
                break;
        }
    }

// ------- UI helpers -------

    function showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add("hidden");
            messageContainer.textContent = "";
        }, 4000);
    }

}

setup_stripe();



